body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.PhoneInputInput,.phoneInputError{
  font-family: 'Public Sans' !important;
  font-weight: 500 !important;
}

.PhoneInputInput:hover {
  border-color:  #69B1FF !important;
}

.PhoneInputInput:focus {
  /* border:  #69B1FF auto 1px !important; */
  border-color:  #69B1FF !important;
  outline: none;
  box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2);
}

.PhoneInputInput:disabled {
  color : rgb(207, 207, 207) !important;
}

.phoneInputError:disabled {
  color : rgb(207, 207, 207) !important;
}

.phoneInputError:focus{
  /* border:  #ff6969 auto 1px !important; */
  border-color:  #fd7272 !important;
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 22, 22, 0.129);
}

.phoneInputError:hover{
  border-color:  #ff8284b8 !important;
}